import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerResearch from '../components/BannerResearch'
import PublicationEntries from '../components/Publications'

//Usin hooks?
import { useState, useEffect } from 'react'

import pic01 from '../assets/images/osl_stairs_ankle.jpg'
import pic02 from '../assets/images/stairascent_walk_l.jpg'
import pic04 from '../assets/images/machine_learning_classif.jpg'
import pic05 from '../assets/images/temperamentscale.png'
import pic06 from '../assets/images/soft_actuator.jpg'

import { navigate } from "gatsby"



const Research = (props) => {    
    const [count, setCount] = useState(2);    

    const incrementCount = () => {
        setCount(count + 1 )
        if (count>5){
            navigate("/publications");
        }
    };

    return (    
    <Layout>
        <Helmet>
            <title>Research - Jonathan Camargo</title>
            <meta name="description" content="Research by Jonathan Camargo" />
        </Helmet>

        <BannerResearch />
        <div id="main">      
            <section id="one">
                    <div className="inner">
                        <header className="major">
                            <h2>Latest publications</h2>
                        </header>                        
                        <PublicationEntries type="journals" counts={count}></PublicationEntries>
                        <div css={{textAlign: "center"}}><a onClick={incrementCount} float="center" className="button">See more</a></div>                        
                    </div>
            </section>

        <section id="two" className="spotlights">                   
                <section>
                    <Link to="/prosthetics" className="image">
                        <img src={pic01} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Prosthetics</h3>
                            </header>
                            <p>Robotic systems are a potential solution for improving the quality of life for individuals with amputations. Some critical aspects of these technologies are: the capacity to generate positive work and localized sensors to obtain information about the system. </p>
                            <ul className="actions">
                                <li><Link to="/prosthetics" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>        
                <section>
                    <Link to="/biomechanics" className="image">
                        <img src={pic02} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Biomechanics</h3>
                            </header>
                            <p>Understanding human motion requires observation of the kinematics and external forces, all associated with a system with hundreds of degrees of freedom.</p>
                            <ul className="actions">
                                <li><Link to="/biomechanics" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/machinelearning" className="image">
                        <img src={pic04} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Machine Learning</h3>
                            </header>
                            <p>Human augmentation systems should recognize the user activity without requiring manual input that imposes additional user efforts. I create models that can predict the user's intent and other state parameters that describe the locomotion. </p>
                            <ul className="actions">
                                <li><Link to="/machinelearning" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/hri" className="image">
                        <img src={pic05} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Human robot interaction</h3>
                            </header>
                            <p>The future of robotics is with systems that can naturally work with humans. My work involves considering the user's needs,
                    and the way they interact with the devices. </p>
                            <ul className="actions">
                                <li><Link to="/hri" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <Link to="/other" className="image">
                        <img src={pic06} alt="" />
                    </Link>
                    <div className="content">
                        <div className="inner">
                            <header className="major">
                                <h3>Other research</h3>
                            </header>
                            <p>Excellent research can emerge by connecting ideas from multiple disciplines. Exploring other research areas has broadened my spectrum of knowledge and improved my core work.</p>
                            <ul className="actions">
                                <li><Link to="/other" className="button">Learn more</Link></li>
                            </ul>
                        </div>
                    </div>
                </section> 
            </section>       
        </div>

    </Layout>   
    );
    }

export default Research

