import React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import Container from './ui/Container';
import TitleSection from './ui/TitleSection';
import FormatHtml from './utils/FormatHtml';
import Timeline from './ui/Timeline';
import { SectionTitle } from '../helpers/definitions';


interface Publication {
    node: {
      id: string;
      html: React.ReactNode;
      frontmatter: {
        name: string;
        authors: string;
        journal: string;
        year: string;
        doi: string;
        link: string;
      };
    };
  }

  const PublicationEntries: React.FC = (props) => {
    
    const  data = useStaticQuery(graphql`
    query{   
      journals:allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "journals" } } }
        sort: { order: DESC, fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              name
              authors
              journal
              year
              doi
              link
            }
          }
        }
      }

      conferences:allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "conferences" } } }
        sort: { order: DESC, fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              name
              authors
              journal
              year
              doi
              link
            }
          }
        }
      }

      books:allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "books" } } }
        sort: { order: DESC, fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              name
              authors
              journal
              year
              doi
              link
            }
          }
        }
      }

      theses:allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "theses" } } }
        sort: { order: DESC, fields: fileAbsolutePath }
      ) {
        edges {
          node {
            id
            html
            frontmatter {
              name
              authors
              journal
              year
              doi
              link
            }
          }
        }
      }
     
    }
  `);
  
    var publications: Publication[]
    if (props.type=='journals'){      
       publications = data.journals.edges;
    }
    else if (props.type=='conferences'){
      publications = data.conferences.edges;
    }        
    else if (props.type=='books'){
      publications = data.books.edges;
    }        
    else{
      publications = data.theses.edges;
    }        
    
    var displayprop: string    

    let test = props.counts;

    let entrystyle={
      paddingBottom: '40px',       
    };

    publications=publications.slice(0,props.counts)
    
    return (      
    
      <Container section>
      <ul>             
      {publications.map((item)=> {
        const{
          id, html,
          frontmatter:{name, authors,journal,year,doi,link}
        }=item.node;        
        return(           
          <li style={entrystyle} className="publicationentry">
          <div >          
          <a href={link}><h4>{name}</h4></a>
          {authors}.<br></br>
          {journal}. {year}.<br></br>          
          <a href={link}>{doi}</a> 
          </div>
          </li>
        );  
       }       
      )
    }
      </ul>     
      </Container>      
      
     
    );
  };



export default PublicationEntries
